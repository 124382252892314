import React, { useContext, useEffect } from 'react'
import { ContainerBackgroundLoading, ContainerCenter, LabelLoading } from './style'
import { useTranslation } from 'react-i18next';
import { useFetchExam } from '../../hooks/useFetchExam';
import { useNavigate, useParams } from 'react-router-dom';
import storage from '../../lib/storage';
import { PatientContext } from '../../contexts';
import { useMixpanel } from 'react-mixpanel-browser';
import { useExam } from '../../contexts/examContext';
import { paymentToken } from '../../lib/api';
import { parseJwt } from '../../utils/functions';

export function LoadingPage() {
    const { t, i18n } = useTranslation()
    const { isFetching, error } = useFetchExam()
    const [exam, setExam] = useExam();
    const mixpanel = useMixpanel();
    const navigate = useNavigate()
    

    useEffect(() => {   
        const hostname = window.location.hostname.includes('vbaby.app')
        const organization = storage.getOrganization()

        if(error != null) {

            if(organization && organization !== 'undefined') {

                navigate(`/${organization}`)
            } else {

                navigate('/')
            }

            return
        }

        if(organization === 'lp-demo') {
            navigate('/watch-demo')
            return
        }
      
        if(!isFetching && exam) {
            const parseToken = parseJwt(storage.getToken())

            const { payment, implementation } = exam

            mixpanel.track('WEBAPP_INIT_EXAM', { 
                'PHONE': parseToken.phone_number,
                'LIVE': parseToken.live,
                'EXAM': parseToken.exam,
                'PAYMENT': !!payment
            });
            
            if(payment) {
                redirect(payment)
                return
            }

            if(implementation === 3) {
                navigate('/medstreamer')
                return
            }

            if((hostname && parseToken.live) || (window.location.hostname.match('assista.urpinheiros.com.br') && parseToken.live)) {
                navigate('/live')
            } else {
                navigate('/watch')
            }
        }
    }, [isFetching, error])

    const redirect = async (payment) => {
     
        const {status, bank_slip_url} = payment

        if (status === 'unknown') {        

          const { data } = await paymentToken()
          window.location.href = data
        }
        
        if (bank_slip_url) {

          window.location.href = bank_slip_url;
        }
    }

    return (
        <ContainerBackgroundLoading>
            <ContainerCenter>
                <LabelLoading style={{color: '#ECF2F8', fontWeight: 800}}>  {t('loadingExam')} </LabelLoading>
            </ContainerCenter>
        </ContainerBackgroundLoading>
    )
}