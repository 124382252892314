import { he } from "date-fns/locale";

export default {
  translations: {
    country: 'Argentina',
    login: {
      phone: 'Teléfono',
      accessCode: 'Código de acceso',
      forgotAcess: 'Olvidé mi código de acceso',
      phoneComponent: {
        searchPlaceholder: 'Buscar país',
        searchNotFound: 'No se encontró ningún país',
      },
    },
    recovery: {
      recoveringCode: 'Recuperando código',
      smsCode: 'Recibirás el código de acceso de tu último examen por SMS en breve',
      didntArrive: '¿No llegó? Puedes solicitar que lo enviemos nuevamente en {{countdown}} segundos',
    },
    notFound: {
      notPage: 'No encontramos esta página',
      openExam: 'Puedes abrir el examen con el número de teléfono y el código de acceso o solicitar un nuevo enlace al remitente.',
      goToLogin: ' Ir al inicio de sesión',
    },
    buttons: {
      enter: 'Ingresar',
      back: 'Volver',
      recovery: 'Recuperar',
      resend: 'Reenviar',
      understood: 'Entendido',
      download: 'Descargar',
      share: 'Compartir',
      copyLink: 'Copiar enlace',
      whatsapp: 'WhatsApp',
      phone: 'Teléfono',
      online: 'Web',
      schedule: 'Agendar',
      help: '¿Necesitas ayuda? Contáctanos',
      sendFeedback: 'Enviar comentarios',
      sendSupport: 'Solicitar ayuda al soporte',
      goToApp: 'IR A LA APLICACIÓN',
      selectFlag: 'Seleccione su idioma',
      jump: 'Saltar',
    },
    loadingExam: 'Cargando examen',
    watch: {
      examDay: 'Examen del día {{date}}',
      realized: 'Realizado el {{date}}',
      statusRecorded: 'Grabado',
      statusLive: 'En vivo',
      statusSoon: 'Próximamente',
    },
    dialog: {
      experience: '¿Cómo está siendo tu experiencia?',
      leaveComment: 'Deja un comentario sobre tu experiencia',
      videoDifficulties: '¡Oops! Hemos detectado un posible problema con la reproducción de tu examen.',
      talkSupport: 'Para una solución rápida, por favor contáctenos de inmediato. ¡Estamos aquí para ayudarte!',
      downloadApp: 'Descarga la aplicación V-Baby',
      downloadingApp: 'Al descargar la aplicación V-Baby, ¡tu experiencia se vuelve más completa! Observa exámenes, sigue el crecimiento de tu bebé y ¡mucho más!',
      errorDownload: '¡Oops! Detectamos un posible problema al descargar tu examen.',
      schedule: 'Agendar examen',
    },
    shareMessages: {
      title: 'Mi Ultrasonido',
      textLive: '¡Hola a todos! 🌟\n¡Mi ultrasonido está sucediendo ahora! Ven a verlo en vivo a través de este enlace: \n',
      textRecorded: '¡Hola! \nLa grabación del ultrasonido de nuestro bebé está disponible. 🌟 \n',
    },
    supportMessages: {
      messageViewUser: '¡Hola!\nEstoy teniendo problemas para ver mis exámenes en Assista.\nNecesito ayuda para resolver el problema.',
      messageDownloadUser: '¡Hola!\nEstoy teniendo problemas para descargar mis exámenes en Assista.\nNecesito ayuda para resolver el problema.',
      messageHelp: '¡Hola! \nEstoy teniendo dificultades con la solución Assista. ¿Podrían ayudarme?',
      askSupport: 'Solicitar suporte',
    },
    alertMessages: {
      errorData: '{{error}} Número de teléfono o código de acceso incorrectos.',
      requiredCode: '¡Se requiere el código de acceso!',
      validNumber: 'Ingresa un número de teléfono válido.',
      sendError: 'Se produjo un error al enviar tu código de acceso, por favor inténtalo nuevamente.',
      copiedLink: 'Enlace copiado al portapapeles',
      errorLink: 'Error al copiar el enlace al portapapeles',
      shareSuccess: '¡Compartido exitosamente!',
      downloadingExam: 'Descargando examen',
      babyDetails: 'Cargando todos los detalles del bebé...',
      preparingVideo: 'Preparando el mejor video...',
      concluded: 'Completado',
      noContent: 'No es posible descargar el examen aún, por favor espere hasta que termine la transmisión en vivo.',
      error: 'Error al descargar el examen',
      downloadError: 'Ocurrió un error inesperado, por favor inténtalo nuevamente más tarde.',
      examLiveError: 'Error al transmitir el examen'
    },
    acceptTerms: {
      privacyPolitics: 'He leído los [termsLink]Términos de Uso[/termsLink] y la [termsPolitic]Política de Privacidad[/termsPolitic].',
      healthPolitics: 'He leído, acepto y consiento el [termsHealth]Término de Recopilación de Datos de Salud[/termsHealth].',      
    },

    poweredBy: {
      title: 'Grabación de ultrasonido por'
    },

    changeLan: {
      label: 'Cambiar idioma:'
    },

    footer: {
      label: "Hecho con 💜 por "
    },

    endedExam: {
      title: '¡Esta transmisión en vivo ha concluido!',
      description: 'La grabación está disponible en la aplicación V-Baby, solo para el número proporcionado en la clínica.',
      descriptionTwo: 'La grabación está disponible en el Resultado de Exámenes - '
    },

    terms: {
      privacyPolitics: "Políticas de Privacidad",
      termsOfUse: "Términos de uso",
      healthTerms: "Término de Recopilación de Datos de Salud"
    },

    endedExamDemo: {
      title: '¡Esta sesión de ultrasonido ya ha finalizado!',
      description: 'Hable con nuestros especialistas y descubra cómo V-Baby puede brindar más comodidad y eficiencia a su día a día.',
    },

    demo: {
        info: 'La transmisión puede tener un retraso de algunos segundos.',
    }
  },
};
