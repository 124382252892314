import axios from 'axios'
import BASE_URL from './base-url.js'

import storage from '../storage'
import { getS3SettingsUrl } from '../../utils/s3.js'
import { publish } from 'pubsub-js'
import { error } from '../../utils/logger.js'

const axiosInstance = axios.create({
    baseURL: BASE_URL
})

export const login = async ({ accessCode, phone, acceptPrivacyTerms, acceptDataTerms }) => axiosInstance.post('/patient/login', {
    'access_code': accessCode,
    'phone_number': phone,
    'accept_privacy_terms': acceptPrivacyTerms,
    'accept_data_sharing_terms': acceptDataTerms,
})

export const loginWithHash = async (data) => axiosInstance.post('/exam/login', {
    ...data
})

export const authenticationGoogleRecaptchaAPI = async (token) => axiosInstance.post('/patient/verify-recaptcha', {
    token: token
})

export const paymentToken = async () => axiosInstance.post('/payment/token', {})

const get = async (url) => {
    const token = storage.getToken()
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axiosInstance.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'

    return await axiosInstance.get(url)
}

export const getPatient = async () => get('/patient')

export const getShareToken = async () => get('/patient/share')

export const getIntroUrl = async (url) => axiosInstance.get(url).catch(({response}) => response)

export const getTsFromM3U8Link = async (url) => axios.get(url).then(response => response.data ? response.data : null).catch((reason) => null)

export const getTSFileFromLink = async (url) => axios.get(url).then(response => response.status ? response.status : 404).catch(response => 404)

export const getUnit = async () => get('/patient/unit')

export const getUrlDownloadExam = async (hash) => {
    return await axiosInstance.get(`${BASE_URL}/patient/download/${hash}`)
}

async function getPatientExam() {
    const URL = `${BASE_URL}/patient`

    const response = await axiosInstance.get(URL)

    return response
}

function paymentBankSlip(data) {
    const URL = `${BASE_URL}/payment/bank-slip`

    return axiosInstance.post(URL, data)
}

export const resendSms = async ({ identifier }) => {
    const URL = `/patient/credentials`;
  
    return axiosInstance.post(URL, {
      identifier
    });
  };

export const ratingUser = async ({ rate, feedback }, hash) => axiosInstance.put(`/patient/rate/${hash}`, {
    'rate': rate,
    'feedback': feedback
})
  
export function getSettings() {
    const URL = getS3SettingsUrl()

    return fetch(URL)
}

export const getM3U8 = async (url) => axiosInstance.get(url)

export const getTS = async (url) => axios.get(url)

export {
    getPatientExam,
    paymentBankSlip,
}

export const loginDemo = async ({ accessCode, phone, acceptPrivacyTerms, acceptDataTerms }) => axiosInstance.post('/patient/login', {
    'is_demo': true,
    'access_code': accessCode,
    'phone_number': phone,
    'accept_privacy_terms': acceptPrivacyTerms,
    'accept_data_sharing_terms': acceptDataTerms,
})

export const getPatientDemo = async () => get('/patient/demo')

export const getStatusDemo = async () => get('/patient/demo/status')

export const getShareTokenDemo = async () => get('/patient/demo/share')

export const postLoginHash = async (data) => axiosInstance.post('/patient/demo/login-hash', {
    ...data
})