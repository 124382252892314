import {
    isEmpty,
    pipe,
    prop,
    replace,
    isNil
} from 'ramda'

import {
    STATUS_LIVE,
    STATUS_RECORDED,
    STATUS_UPCOMING
} from '../constants'

import {
    LIVE_MODE,
    DEV_MODE,
    DEBUG_MODE
} from '../env'

import { useTranslation } from 'react-i18next';
import { MUSIC_URL } from '../../fetcher/constants'

/**
 * 
 * @param {string} url 
 */
const transformURL = pipe(
    replace(/^\/\//, 'https://'),
    url => LIVE_MODE ? `${url}?live=true` : url
)

/**
 * 
 * @param {object} vod 
 * @param {string} live_url 
 */
const getValidURL = ({ vod, live_url }) =>
    isEmpty(live_url) || isNil(live_url) ? vod[0].url : live_url


/**
 * 
 * @param {object} vod 
 * @param {string} live_url 
 */
const getIntroURL = ({ vod, live_url }) => vod[0].intro_url


export const getStreamURL = pipe(getValidURL, transformURL)

/**
 * 
 * @param {object} exam 
 */
const getExamStatus = ({ live_url }) =>
    live_url ? STATUS_LIVE : STATUS_RECORDED

/**
* 
* @param {object} exam 
*/

const formattedDateNow = () => {
    const date = new Date();
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    
    return formattedDate;
}
const extractDateValue = ({ live_url, vod }) =>
    live_url ? formattedDateNow() : vod[0]['created_at']

/**
 * 
 * @param {Date} date 
 */

const getDownloadLink = pipe(
    ({ vod }) => vod[0],
    prop('mp4'),
    prop('url')
)


const getExamDate = pipe(
    extractDateValue,
)

/**
 * 
 * @param {object} data 
 */
export const examFactory = data => ({
    liveUrl: data.live_url ? data.live_url : '', 
    m3u8: getStreamURL(data),
    showIntro: data.show_intro,
    examId: data.exam,
    cycle: data.cycle,
    introURL: getIntroURL(data),
    linkDownload: getDownloadLink(data),
    examStatus: getExamStatus(data),
    date: getExamDate(data),
    mp4Url: data.vod[0].mp4 === null ? null : data.vod[0].mp4.url,
    showRate: data.show_rate,
    payment: data.payment ? data.payment : null,
    flowType: data.flow_type ? data.flow_type : 0,
    organizationDescription: data.organization_description ? data.organization_description : '',
    implementation: data.implementation,
})

/**
 * @todo Utilizar do component de Player
 */
export const hlsConfigFactory = token => ({
    xhrSetup: xhr =>
        token && xhr.setRequestHeader('Authorization', `Bearer ${token}`),
    nudgeOffset: 4,
    nudgeMaxRetry: 60,
    maxMaxBufferLength: 12,
    debug: DEBUG_MODE || DEV_MODE
})


export const getStatusLabel = (status, i18n) => {
    if (status === STATUS_RECORDED) {
      return i18n.t('watch.statusRecorded');
    } else if (status === STATUS_LIVE) {
      return i18n.t('watch.statusLive');
    } else if (status === STATUS_UPCOMING) {
      return i18n.t('watch.statusSoon');;
    }
  };
  

  let phoneNumber = '';

  export const setGlobalPhone = (newPhoneNumber) => {
    phoneNumber = newPhoneNumber;
  };
  
  export const getGlobalPhone = () => phoneNumber;

  export const parseJwt = (token) => {
    if (!token) return;

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const settingsFactory = (data) => ({
    country: data.country || "BR",
    name: data.name || "VBaby",
    app: {
        showAccessApp: false
    },
    loginButton: {
        backgroundColor: data.loginButton?.backgroundColor || "#0166ff",
        borderColor: data.loginButton?.borderColor || "#999999",
        textColor: data.loginButton?.textColor || "#FFFFFF"
    },
    live: {
        backgroundColor1: data.live?.backgroundColor1 || "#B796EE",
        backgroundColor2: data.live?.backgroundColor2 || "#A896E6",
        textColorLive: data.live?.textColor || "#FFFFFF",
        logoTheme: data.live?.logoTheme || "light",
        backgroundImage: data.live?.backgroundImage || "https://vlab-assets.vlab.live/assista_vlab_live/background.png",
        useBackgroundImage: data.live?.useBackgroundImage || true
    }
});

export const unitFactory = (data) => ({
    code: data.code ?? "",
    description: data.description ?? "",
    address: data.address ?? "",
    city: data.city ?? "",
    state: data.state ?? "",
    phone: data.phone ?? "",
    zipcode: data.zipcode ?? "",
    whatsapp: data.whatsapp ?? "",
    site: data.site ?? "",
    logo_colorful: data.logo_colorful ?? "",
});